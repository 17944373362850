import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Popover } from 'antd';
import { stringReducer } from "../actions";
import { useSelector, useDispatch } from "react-redux";
import logo from "../img/text-logo2.png";
import global from "../img/common/global.png";

const SignUpOk = () => {
    const string = useSelector(state => state.stringReducer.string);
    const language = useSelector(state => state.stringReducer.language);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [globalOpen, setGlobalOpen] = useState(false);

    const changeLan = (lan) =>{ dispatch(stringReducer(lan)) };
    return (
        <div id="signupok">
             <div className='header'>
                <Popover                
                    content={
                        <div className='pophover'>
                            <div onClick={()=>changeLan('ko')} className={language == 'ko' ? "lan active": "lan"}>Korean</div>
                            <div onClick={()=>changeLan('en')} className={language == 'en' ? "lan active": "lan"}>English</div>
                            <div onClick={()=>changeLan('ja')} className={language == 'ja' ? "lan active": "lan"}>Japanese</div>
                        </div>                    
                    }
                    trigger={"click"}
                    open={globalOpen}
                    onOpenChange={()=>setGlobalOpen(!globalOpen)}>
                    <img src={global} className='globalImg'/>
                </Popover>
            </div>
            <div className='content'>
                <img src={logo} className='logoImg'/>
                <p className='welcome'>{string.welcome}</p>
                <p className='content'>
                    {string.signUpMsg01}<br/>
                    {string.signUpMsg02}</p>
            </div>
            <div className='btnbox'>
                <button className='loginBtn' onClick={()=> navigate('/')} >{string.login}</button>
            </div>
        </div>
    )
}

export default SignUpOk;