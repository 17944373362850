import { Modal, Popover, Button } from "antd";
import React, {useEffect, useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import { setLogout, stringReducer } from "../actions";
import { useNavigate } from "react-router-dom";
import logo from "../img/text-logo1.png";
import global from "../img/common/global.png";
import logoutimg from "../img/common/logout.png";
import { httpPost, httpUrl } from '../api/httpClient';
import { ScanOutlined } from '@ant-design/icons';
// import QrReader from 'react-qr-scanner'
import { QrReader } from 'react-qr-reader';

const Top = (props) => {
  const dispatch = useDispatch();
  const language = useSelector(state => state.stringReducer.language);
  const string = useSelector(state => state.stringReducer.string);
  const navigate = useNavigate();
  const [globalOpen, setGlobalOpen] = useState(false);
  const [qrReadModalOpen, setQrReadModalOpen] = useState(false);
  
  const logout = () => {
    Modal.confirm({
        title: string.myinfoLogout,
        content: string.myinfoLogoutConfirm,
        okText: string.ok,
        cancelText: string.cancel,
        onOk() {
          httpPost(httpUrl.logout, [])
            dispatch(setLogout());
            navigate('/', {state: { logout: true}});
        },
        onCancel() {
        },
    });
  }

  const changeLan = (lan) =>{ 
    dispatch(stringReducer(lan)) 
  };  
  const handleCancel = () => {
    setQrReadModalOpen(false);
  };

  return(
    <div id="top">
      <img src={logo} style={{width: 200}}/>  
      <div className="iconContainer">
        <Popover                
          content={
              <div className='pophover'>
                  <div onClick={()=>changeLan('ko')} className={language == 'ko' ? "lan active": "lan"}>Korean</div>
                  <div onClick={()=>changeLan('en')} className={language == 'en' ? "lan active": "lan"}>English</div>
                  <div onClick={()=>changeLan('ja')} className={language == 'ja' ? "lan active": "lan"}>Japanese</div>
              </div>                    
          }
          trigger={"click"}
          open={globalOpen}
          onOpenChange={()=>setGlobalOpen(!globalOpen)}>
          <img src={global} className='globalImg'/>
        </Popover>    
        <div style={{cursor: 'pointer', margin: '0 5px'}} onClick={()=>{setQrReadModalOpen(true)}}>
        <ScanOutlined />
        </div>
        <div onClick={logout}>
          <img src={logoutimg} className='globalImg' style={{ marginTop: 4 }}/>  
        </div>
      </div>
      <Modal
        open={qrReadModalOpen}
        oncancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
          CLOSE
        </Button>,
        ]}>
        <div>   
        <QrReader
          constraints={{facingMode: 'environment'}}
          onResult={(result, error) => {
            if (result != null) {
              httpPost(httpUrl.approveLoginCode, [result], {}, true);
              handleCancel();
            }
          }}
          style={{ width: '100%' }}
        />     
          {/* <QrReader
            delay={100}
            style={{
              height: 240,
              width: 320,
            }}
            onScan={(data) => {
              if (data == null) return;
              console.log(data)
              alert(data)
              // httpPost(httpUrl.approveLoginCode, [data], {}, true);
            }}
            facingMode={"front"}
            onError={()=>{}}
            /> */}
          </div>
      </Modal>
    </div>    
  )
}

export default Top;