import { Input, Modal, Popover} from 'antd';
import React, { useState, useEffect } from 'react';
import { httpUrl, httpPost } from "../api/httpClient";
import globalImg from "../img/common/global.png";
import userImg from "../img/common/user.png";
import lock from "../img/common/lock.png";
import checkOff from "../img/common/checkOff.png"
import checkOn from "../img/common/checkOn.png"
import { stringReducer, setLogin } from "../actions";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
import Const from '../const';
import SHA256 from 'crypto-js/sha256';
import { enc } from 'crypto-js';
import logo from "../img/text-logo1.png";
import QRCode from "react-qr-code";

const Login = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const logout = location.state ? location.state.logout : false;
    const language = useSelector(state => state.stringReducer.language);
    const string = useSelector(state => state.stringReducer.string);    
    const navigate = useNavigate();
    const [user, setUser] = useState({
        id: '',
        password: '',
    });
    const [loginSetting, setLoginSetting] = useState({
        savedId:false,
        autoLogin:false
    });
    const [globalOpen, setGlobalOpen] = useState(false);
    const [loginCode, setLoginCode] = useState("");

    let loginCodeInterval = null;
    useEffect(() => {    
        const loginSetting = reactLocalStorage.getObject(Const.appCode + "#loginSetting");  
        if (loginSetting) {
            const userData = reactLocalStorage.getObject(Const.appCode + "#user");          
            if (loginSetting.savedId) {        
                setUser({id: userData.id});
                setLoginSetting({ savedId:true, autoLogin:false })
            } else if (loginSetting.autoLogin) {
                setUser({id: userData.id});
                setLoginSetting({ savedId:false, autoLogin:true});       
                if (!logout) { autoLogin(userData)};
            };
        }

        getLoginCode();
        loginCodeInterval = setInterval(getLoginCode, 3000)

        return ()=>{
            clearInterval(loginCodeInterval)
        }
        // 

    }, []);
    const getLoginCode = async ()=>{
        try {
            const res = await httpPost(httpUrl.loginCode, [], {machineId: global.gameId}, true);
            if (res.data.authId != null) {
                dispatch(setLogin(res.data.authUser));
                navigate('/Currency');
            }
            else {
                if (loginCode != res.data.loginCode) {
                    setLoginCode(res.data.loginCode);
                }
            }
        }
        catch(e) {

        }
    }

    const autoLogin = async (userData) => {
     let password = userData.password + userData.id;
     let passwordHash = SHA256(password).toString(enc.Hex);
     userData.password = passwordHash;
        try {
            const res = await httpPost(httpUrl.login, [], userData);
            resultFuc(res);
        // if (res.data.result) {
        //     dispatch(setLogin(res.data.user));
        //     reactLocalStorage.setObject(Const.appCode + "#userinfo", res.data.user);                
        //     if (loginSetting.savedId || loginSetting.autoLogin) {
        //         reactLocalStorage.setObject(Const.appCode + "#user", user);
        //         reactLocalStorage.setObject(Const.appCode + "#loginSetting", loginSetting);
        //     }              
        //     navigate('/Currency');
        // } else { loginError(res.data) };
        } catch(e){
            Modal.info({
                title: string.login,
                content: string.loginFailedMessage,
                onOk() { },
            });  
        }
    };

    const resultFuc = (res) => {
        const result = res.data;
        if (result.result) {
            dispatch(setLogin(result.user));
            reactLocalStorage.setObject(Const.appCode + "#userinfo",result.user);                
            if (loginSetting.savedId || loginSetting.autoLogin) {
                reactLocalStorage.setObject(Const.appCode + "#user", user);
                reactLocalStorage.setObject(Const.appCode + "#loginSetting", loginSetting);
            }             
            else {
                reactLocalStorage.remove(Const.appCode + "#user");
                reactLocalStorage.remove(Const.appCode + "#loginSetting");
            } 
            navigate('/Currency');
        } else {
            let message = string.loginFailedMessage;
            if (result.reason == 'INVALID_LOGIN_ID') message = string.loginFailedWrongId;
            else if (result.reason == 'INVALID_LOGIN_PASSWORD') message = string.loginFailedWrongPassword;
            else if (result.reason == 'INVALID_LOGIN_INFO') message = string.loginFailedInvalidLoginInfo;
            else if (result.reason == 'EXPIRED') message = string.loginFailedExpiredId;
            else if (result.reason == 'EMAIL_CONFIRM_NOT_YET') message = string.signUpMsg03;
            Modal.info({
                title: string.loginFailedTitle,
                content: message,
                onOk() { },
            });
        }
    };
    
    const handleSubmit = () => {
        let users = {...user};
        if (users.id === '' || users.id === undefined) {
            alert(string.signUpRuleMsg09);
            return;
        }
        if (user.password === '' || user.password === undefined) {
            alert(string.signUpRuleMsg10);
            return;
        }   
        let password = user.password + user.id;
        let passwordHash = 
        SHA256(password)
            // .createHash('sha256')
            // .update(password, 'utf8')
            // .digest()
            .toString(enc.Hex);
        users.id = user.id;
        users.password = passwordHash;
        users.deviceId = global.gameId;
        httpPost(httpUrl.login, [], users).then((result) => {
            console.log("## login result: " + JSON.stringify(result, null, 4));
            resultFuc(result);
            // result = result.data;
            // if (result.result) {
            //     console.log("## login success");
            //     dispatch(setLogin(result.user));
            //     reactLocalStorage.setObject(Const.appCode + "#userinfo", result.user);                
            //     if (loginSetting.savedId || loginSetting.autoLogin) {
            //         reactLocalStorage.setObject(Const.appCode + "#user", user);
            //         reactLocalStorage.setObject(Const.appCode + "#loginSetting", loginSetting);
            //     }              
            //     navigate('/Currency');
            // } else { loginError(result) }
        }).catch(e => {
            console.log('## signUp error: ' + e);
            Modal.info({
                title: string.login,
                content: string.loginFailedMessage,
                onOk() { },
            });           
        });
    };

    const onChange = (type) => {
        if (type === 'save') {
            setLoginSetting({
                savedId:!loginSetting.savedId,
                autoLogin:false
            })   
        } else {
            setLoginSetting({
                savedId:false,
                autoLogin:!loginSetting.autoLogin
            })   
        }             
    };

    const changeLan = (lan) =>{ dispatch(stringReducer(lan)) };
    

    return (
        <div id="login"> 
            {/* 헤더 */}
            <div className='header'>
                <a href='https://bluecoin.biz/bluecoin.apk' style={{textDecorationLine: 'none'}}>
                <div style={{border: '1px solid #354b9e', padding: '5px 10px', borderRadius: '5px', color: '#354b9e', textDecotextDecorationLineration: 'none'}}>앱 다운로드</div>
                </a>
                <Popover                
                    content={
                        <div className='pophover'>
                            <div onClick={()=>changeLan('ko')} className={language == 'ko' ? "lan active": "lan"}>Korean</div>
                            <div onClick={()=>changeLan('en')} className={language == 'en' ? "lan active": "lan"}>English</div>
                            <div onClick={()=>changeLan('ja')} className={language == 'ja' ? "lan active": "lan"}>Japanese</div>
                        </div>                    
                    }
                    trigger={"click"}
                    open={globalOpen}
                    onOpenChange={()=>setGlobalOpen(!globalOpen)}>
                    <img src={globalImg} className='globalImg'/>
                </Popover>
            </div>
            <div className='container'>


                <QRCode    
                    size={100}
                    color='#000'
                    value={loginCode}
                    style={{paddingBottom: '30px'}}
                />
                    {/* <div>loginCode:{loginCode}</div> */}

                <img src={logo} className='logoImg'/>
                <div className='formBox'>
                    <img src={userImg} style={{width: 20, marginRight: 16, marginLeft: 16}}/>
                    <input className="login-input"
                        autoComplete='false'
                        placeholder={string.idEmail}
                        style={{margin: 0}}
                        value={user.id}
                        onChange={(e)=>setUser({...user, id: e.target.value.trim()})}
                    />
                </div>
                <div className='formBox'>
                    <img src={lock} style={{width: 20, marginRight: 16, marginLeft: 16}}/>
                    <Input.Password 
                        className="login-input"
                        style={{margin: 0, border: 'none', background:'transparent', boxShadow: 'none'}}
                        placeholder={string.password} 
                        value={user.password}
                        onChange={(e)=> setUser({...user, password: e.target.value})}
                        onKeyDown={e=>{
                            if (e.key =='Enter') handleSubmit();
                        }}
                        />
                    {/* <input className="login-input"
                        autoComplete='false'
                        placeholder={string.password}
                        type='password'
                        style={{margin: 0}}
                        value={user.password}
                        onChange={(e)=> setUser({...user, password: e.target.value})}
                        onKeyDown={e=>{
                            if (e.key =='Enter') handleSubmit();
                        }}
                    /> */}
                </div>
                <div className='loginOptionBox'>
                    <div onClick={()=>onChange('save')} className='option'>
                        <img src={loginSetting.savedId ? checkOn : checkOff} style={{width: 14,marginRight: 6}} />
                        <p className={loginSetting.savedId && 'optionActive'}>{string.savedId}</p>
                    </div>
                    <div onClick={()=>onChange('auto')} className='option'>
                        <img src={loginSetting.autoLogin ? checkOn : checkOff} style={{width: 14, marginRight: 6}} />
                        <p className={loginSetting.autoLogin && 'optionActive'}>{string.autoLogin}</p>
                    </div>
                </div>
                <button className='loginBtn' type="primary" htmlType="submit" onClick={handleSubmit}>{string.login}</button>
                <div className='loginOptionBox'>
                    <div onClick={() => navigate('/modifyPassword')} className='findPw'>{string.findPw}</div>
                    <div className='slash'>/</div>
                    <div onClick={() => navigate('/signup')} className='signup'>{string.signUp}</div>
                </div>
            </div>           
        </div>
    )
}

export default Login;